@import "./../../../styles/variables";

.communication-possible-disable-modal {
  .modal-content {
    background-color: $primary;
  }
  .modal-header {
    border: none;
    .modal-title {
      font-size: 15px;
    }
    button {
      display: none;
    }
  }
  .modal-body {
    font-size: 13px;
    .disable-message-text-wrap {
      .disable-message-text {
        width: 100%;
        height: 7rem;
        background: #000;
        margin-top: 1rem;
        outline: none;
        border: none;
        &::-webkit-input-placeholder {
          text-align: center;
          padding: 2rem;
          color: rgba(255,255,255,0.4);
        }

        &:-moz-placeholder {
          text-align: center;
          padding: 2rem;
          color: rgba(255,255,255,0.4);
        }

        &::-moz-p {
          text-align: center;
          padding: 2rem;
          color: rgba(255,255,255,0.4);
        }

        &:-ms-input-placeholder {
          text-align: center !important;
          padding: 2rem !important;
          color: red !important;
        }
        &::placeholder {
          text-align: center;
          padding: 2rem;
          color: rgba(255,255,255,0.4);
        }
      }
    }
  }
  .modal-footer {
    border: none;
    button {
      font-size: 13px;
    }
  }
}
