@import "./../../../../styles/variables";

.periodic-maintenance-form {
  margin: 0;
  padding: 0 !important;
  .align-left {
    margin-left: 25px;
  }
  .form-group {
    padding-right: 15px;
    padding-left: 15px;
  }
  .recurrence-pattern {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 13px;
    .heading {
      width: 100%;
      padding-bottom: 0.5rem;
    }
    .options {
      width: 20%;
    }
    .value {
      width: 78%;
      padding: 0px 0 0 27px;
    }
    .weekly {
      display: flex;
      flex-wrap: wrap;
      label {
        margin-bottom: -3px;
        margin-left: 5px;
      }
      .each-day {
        padding: 5px;
        width: 7rem;
      }
      .day-name {
        text-transform: capitalize;
      }
    }
  }
  input[type="number"],
  input[type="tel"] {
    background-color: $light-black;
    border: none;
    color: $font-color;
    font-size: 13px;
    box-shadow: none;
    display: inline-block;
    width: 2.3rem;
    height: 27px;
    margin: 0 7px;
    padding: 7px;
  }
  .quantity::-webkit-inner-spin-button,
  .quantity::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  .line {
    border-left: 1px solid $teal-blue;
  }
  @media screen and (max-width: 1024px) {
    padding: 0;
  }
  input[type="radio"] {
    display: none;
  }
  .custom-datepicker {
    input {
      background-color: hsla(0, 0%, 49.8%, 0.19) !important;
    }
  }
}
