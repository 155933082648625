@import "./../../../styles/_variables";

.alert-form {
  .d-flex {
    display: flex;
    align-items: center;
    &.fill {
      width: 100%;
      height: 100%;
      flex-direction: column;
    }
  }
  .tt-none {
    text-transform: none !important;
  }
  .jc-center {
    justify-content: center;
  }
  padding: 0 130px 0 25px;
  margin: 0;

  .align-left {
    margin-left: 25px;
  }
  @media screen and (max-width: 1024px) {
    padding: 0;
  }
  input[type="radio"] {
    display: none;
  }

  input[type="radio"] + label.type-radio {
    display: inline-block;
    outline: 0;
    background-image: url("./../../../assets/icons/Radio_button-unchecked.svg");
    background-repeat: no-repeat;
    background-position: 0 0;
    text-indent: 19px;
  }

  input[type="radio"]:checked + label.type-radio {
    background-image: url("./../../../assets/icons/Radio_button-checked.svg");
  }

  .custom-datepicker {
    .rdtPicker {
      background-color: $blue-green;
      border: none;
      color: $font-color;

      .rdtOld,
      .rdtNew {
        color: $skobeloff;
      }

      .rdtToday::before {
        display: none;
      }

      .rdtActive {
        background-color: rgb(1, 80, 94);
      }
    }
  }

  .custom-multi-select {
    .css-1hwfws3:focus {
      outline: none;
    }
    .css-hjm3sl:hover,
    .css-hjm3sl:focus {
      outline: none;
      border-color: transparent !important;
    }

    // dropdown selector
    .css-15k3avv {
      background-color: $primary;
    }
    // search input
    .css-1g6gooi {
      color: $font-color;
    }
    .css-1492t68 {
      // display: contents;
      // padding-top: 2%;
    }
  }
  .delete-periodic {
    margin-top: 34px;
  }
  .actions {
    min-width: 110px;
    width: auto;
    margin-right: 20px;
    margin-top: 3rem;
    @media screen and (max-width: 600px) {
      margin-top: 1rem;
      width: 100%;
    }
  }

  .css-1lxd980 {
    box-shadow: none !important;
    &:hover {
      border-color: none;
    }
  }
  .css-1r36co3 {
    box-shadow: none !important;
  }
  .css-1quhzxg {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-right: 6px;
  }
  .css-go1snr {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .custom-datepicker {
    &:before {
      content: "";
      width: 15px;
      height: 15px;
      background: url("../../../assets/icons/Shape_2.svg") no-repeat;
      background-position: center center;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }
    &.timepicker {
      input {
        background: url("../../../assets/icons/timepicker.png");
        background-color: hsla(0, 0%, 49.8%, 0.19) !important;
        background-position: left 10px center;
        // padding-left: 56px;
        // padding-right: 51px;
        background-repeat: no-repeat;
        background-size: 20px 20px;
      }
    }
    input {
      background: url("../../../assets/icons/Calendar.svg") no-repeat;
      background-position: left 10px center;
      background-color: #181818;
      padding-left: 40px;
      padding-right: 33px;
    }
  }
  textarea {
    padding: 10px;
  }
  .periodic {
    width: 100%;
  }
  .form-group {
    p {
      margin-bottom: 0;
    }
  }
  @media (min-width: 768px) {
    .language-switcher-left {
      position: relative;
      padding-right: 8px;
      button {
        font-size: 0;
        width: 29px;
        height: 27px;
        border-radius: 50%;
        border: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 10px;
        z-index: 1;
        right: -10px;
        // cursor: pointer;
        background: url("../../../assets/icons/Shape_5.svg") no-repeat;
        background-position: center center;
        background-color: rgba(2, 133, 157, 1);
        background-size: 17px 19px;
      }
    }
    .language-switcher-right {
      padding-left: 2px;
    }
  }
  .rdtPicker .rdtTimeToggle {
    font-size: 14px;
    padding: 10px 0;
  }
  .start-periodical,
  .start-periodical-maintenance {
    padding: 0 12.5px 0 25px;
  }
  .end-periodical,
  .end-periodical-maintenance {
    padding: 0 25px 0 25px;
  }
  @media (max-width: 1389px) {
    .periodical-maintenance {
      width: 100%;
      flex: auto;
      max-width: 100%;
    }
    .start-periodical-maintenance,
    .end-periodical-maintenance {
      width: 50%;
      flex: auto;
      max-width: 100%;
      padding: 0 25px 0 25px;
    }
  }
  @media (max-width: 1000px) {
    .reason {
      width: 100%;
      flex: auto;
      max-width: 100%;
    }
    .start-periodical,
    .end-periodical {
      width: 50%;
      flex: auto;
      max-width: 100%;
      padding: 0 25px 0 25px;
    }
  }
  @media (max-width: 767px) {
    .form-group {
      width: 100%;
      flex: auto;
      max-width: 100%;
    }
    .align-left {
      margin-left: 0;
    }
    .language-switcher-left {
      button {
        width: 100%;
        background-color: rgba(0, 0, 0, 1);
        border: 1px solid rgba(2, 133, 157, 1);
        border-radius: 3px;
        box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.71);
        padding: 10px 0;
        margin-top: 12px;
        font-family: "Poppins";
        font-size: 12px;
        color: rgba(2, 133, 157, 1);
        cursor: pointer;
        &:before {
          content: "";
          display: inline-block;
          background: url("../../../assets/icons/Shape_3.svg") no-repeat;
          height: 15px;
          width: 15px;
          margin-right: 10px;
          vertical-align: middle;
        }
      }
    }
  }

  .rdtPicker thead tr:first-child th:hover {
    background: rgba(24, 24, 24, 1);
  }
  .rdtPicker {
    padding: 0;
  }
  .rdtPicker th.rdtSwitch {
    background: #181818;
    border-bottom: none;
  }
  .rdtPrev span,
  .rdtNext span {
    background: #181818;
  }
  .rdtPicker th.rdtNext,
  .rdtPicker th.rdtPrev {
    background: #181818;
    border: none;
  }
  .rdtPicker {
    table {
      thead {
        tr {
          &:nth-child(2) {
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          }
        }
      }
    }
  }

  .custom-datepicker input {
    box-shadow: none;
  }

  .rdtPicker td.rdtDay:hover,
  .rdtPicker td.rdtHour:hover,
  .rdtPicker td.rdtMinute:hover,
  .rdtPicker td.rdtSecond:hover,
  .rdtPicker .rdtTimeToggle:hover {
    background: #02859d;
  }
  .rdtPicker tfoot {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
  .css-d8oujb {
    display: none;
  }
  .css-1ep9fjw {
    margin-right: 9px;
    cursor: pointer;
    &:first-child {
      background: url("../../../assets/icons/close.svg") no-repeat;
      background-position: center;
      padding-right: 34px;
    }
    &:last-child {
      background: url("../../../assets/icons/Sort_desc.svg") no-repeat;
      background-position: center;
      background-position: center center;
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
    }
    svg {
      display: none;
    }
  }
  .css .css-19bqh2r,
  .css-1uq0kb5 {
    margin-right: 9px;
    &:last-child {
      background: url("../../../assets/icons/Sort_asc.svg") no-repeat;
      background-position: center;
    }
    &:first-child {
      background: url("../../../assets/icons/close.svg") no-repeat;
      background-position: center;
    }
    svg {
      display: none;
    }
  }
  .css-xp4uvy {
    color: $font-color !important;
  }
  .dates-periodic {
    background: rgba(24, 24, 24, 1) !important;
    border: none;
    outline: none;
    font-size: 12px;
    background-position: left 10px center;
    background-color: #181818;
  }
  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label {
    background: url("./../../../assets/icons/Checkbox_unchecked.svg") no-repeat;
    background-size: 100%;
    height: 14px;
    width: 14px;
    display: inline-block;
    padding: 0 0 0 0;
  }

  input[type="checkbox"]:checked + label {
    background: url("./../../../assets/icons/Checkbox.svg") no-repeat;
    background-size: 100%;
    height: 14px;
    width: 14px;
    display: inline-block;
    padding: 0 0 0 0;
  }
  .periodic-enable {
    label {
      margin-bottom: -3px;
      cursor: pointer;
    }
    .ml-5 {
      margin-left: 5px !important;
    }
  }

  .col-wd {
    width: 24%;
    flex: none;
  }
  .recurrence-info {
    color: $blue-green;
    min-width: 20%;
    font-weight: bolder;
    margin: 0 18px;
    padding: 0 9px;
    display: inline-block;
  }

  // Updated Recurrence Options
  .recurrence-options-form {
    // min-width: 150px;
    box-shadow: 0 0 2px #fff;
    margin-top: 10px;
    display: flex;
    border-radius: 5px;
    height: 350px;
    .recurrence-options {
      background: rgba(0, 0, 0, 0.5);
      // width: 200px;
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .option {
        margin-bottom: 1.5rem;
        &:last-child {
          margin-bottom: 0.5rem;
        }
      }
    }
    .recurrence-option-details {
      flex: 3;
      position: relative;
      padding: 1.5rem 2rem;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 1rem 2rem;
      .recurrence-option-value-wrap {
        width: 100%;
      }
      .active-time-range {
        display: flex;
        margin-top: 1rem;
        width: 100%;
        .custom-datepicker {
          margin-right: 2rem;
          max-width: 150px;
          .rdtPicker {
            width: 100%;
          }
        }
      }
      &::before {
        content: '';
        position: absolute;
        height: 150px;
        width: 1px;
        top: 50%;
        margin-top: -75px;
        left: 0;
        background: $teal-blue;
      }
      input[type="number"],
      input[type="tel"] {
        background-color: $light-black;
        border: none;
        color: $font-color;
        font-size: 13px;
        box-shadow: none;
        display: inline-block;
        width: 2.4rem;
        height: 27px;
        margin: 0 7px;
        padding: 7px;
      }
      .quantity::-webkit-inner-spin-button,
      .quantity::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }
      .weekly {
        width: 100%;
        .each-day {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 2rem;
          height: 2.5rem;
          margin-bottom: 2px;
          .day-select-wrap {
            flex: 1;
            width: 100%;
            height: 100%;
          }
          .time-range-wrap {
            max-width: 300px;
            flex: 3;

          }
          label {
            margin-right: 5px;
          }
        }
        .custom-datepicker {
          margin-left: 2rem;
          .rdtPicker {
            width: 100%;
          }
        }
      }
    }
    .recurrence-details-text {
      flex: 2;
      padding: 3rem 2rem;
      font-size: 12px;
      color: $blue-green;
      font-weight: bolder;
      position: relative;
      display: flex;
      align-items: center;
      text-transform: none;
      &::before {
        content: '';
        position: absolute;
        height: 150px;
        width: 1px;
        top: 50%;
        margin-top: -75px;
        left: 0;
        background: $teal-blue;
      }
    }
  }
}
