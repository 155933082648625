.api-validator-container {
    padding-right: 15px;
    .upload-btn {
        padding: 0px;
        &:disabled {
            background: none;
            cursor: pointer;
        }
    }
    .request-response {
        width: 85%;
        display:flex;
        align-items: center;
        margin-top: 1rem;
        textarea {
            width: 80%;
            height: 300px;
            overflow: auto;
            margin-bottom: 1rem;
            font-family: monospace;
        }
        .submit {
            margin-left: 26px;
        }
    }
   
    .file-upload {
        opacity: 0;
        width: 0;
    }
    .download {
        margin-bottom: 10px;
    }
}