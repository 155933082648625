.systems {
    .custom-row-class { td {
        background: linear-gradient(rgba(0, 0, 0, 0.75), #005999c2), url('./../../assets/images/systems.jpg') !important; 
    background-size: 100% 100% !important;
    }
    .delete {
        cursor: pointer;
    }
    &.disable {
        .delete {
            cursor: auto;
        }
    }
    }
}
    
