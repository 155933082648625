@import "./../../styles/variables";

.list-container {
  padding-right: 15px;

  .heading {
    flex-grow: 1;
    padding-top: 7px;
  }
  .right-align {
    margin-left: auto;
  }
  .count {
    font-size: 12px;
  }
  .react-bootstrap-table {
    overflow: auto !important;
    width: auto;

    .table {
      min-width: 658px;
      table-layout: fixed;
      th {
        word-break: break-word;
      }
      tr {
        .expandable {
          max-height: 50px;
          overflow: hidden;
        }
        &.expanded {
          .expandable {
            max-height: none;
          }
        }
      }
      td {
        text-transform: capitalize;
        font-size: 13px;
        color: $white-70;
        background-color: #a6cad8;
        word-break: break-all;
        vertical-align: middle;
        &:nth-child(1) {
          text-align: center;
        }
      }
      .message-col {
        // max-height: 50px;
        // overflow: hidden;
        transition: 0.3s;
        text-overflow: ellipsis;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        // &.expanded {
        //   max-height: none;
        // }
        .expand-text-btn {
          position: absolute;
          bottom: -18px;
          left: 0;
          right: 0;
          font-size: 12px;
          font-weight: 700;
          text-align: right;
          padding: 5px;
          cursor: pointer;
        }
      }
      // .de {
      //   th:nth-child(3),
      //   th:nth-child(4) {
      //     width: 11% !important;
      //   }
      //   th:nth-child(8) {
      //     width: 187px !important;
      //   }
      // }
      th:first-child {
        width: 3.4%;
        text-align: center;
      }
      th:nth-child(2) {
        width: 9.5%;
      }
      th:nth-child(3),
      th:nth-child(4),
      th:nth-child(5) {
        width: 8%;
      }
      th:nth-child(7) {
        width: 10%;
      }
      th:nth-child(6) {
        width: 6.8%;
      }
      th:nth-child(8) {
        width: 15%;
        word-break: break-all;
      }
      th:nth-child(9) {
        // width: 38%;
        width: 4%;
      }
      th:nth-child(10) {
        width: 70px;
      }
      tr.upcoming td {
        color: $font-color !important;
        background-color: $rich-black;
        font-size: 10px;
      }
      tr.expired td {
        background-color: #707070;
        font-size: 10px;
      }
      tr.active td {
        background-color: #007a93;
      }
      tr.emergency td {
        background-color: #9f1923;
      }
      tr.unauthorized td:nth-child(10) {
        box-shadow: none;
      }
      tr:first-child {
        padding-top: 10px;
      }
    }
  }
}
.active {
  .popup-more,
  .popup-more button {
    // background-color: #007a93 !important;
    background-color: rgba(0, 0, 0, 0) !important;
  }
  svg {
    fill: #007a93;
  }
}

.planned {
  svg {
    fill: $rich-black;
  }
}
.expired svg {
  fill: #707070;
}
.emergency {
  svg {
    fill: #9f1923;
  }
}
hr {
  margin-top: 0px !important;
}
.emergency-info {
  // float: right;
  button,
  button:active,
  button:focus,
  button:hover {
    margin-top: 0;
    background: rgba(0, 0, 0, 0) !important;
  }
  img {
    width: 20px;
  }
  .popover-body {
    top: -1009px !important;
  }
}
.periodic-popover {
  // float: right;
  // margin-top: -4px;
  img {
    width: 20px;
    background: $rich-black;
  }
  .popover {
    left: -8px !important;
  }
  &.active img {
    background: #007a93 !important;
  }
}
.periodic-info-popover {
  left: -10px !important;
  .popover {
    max-width: 3000px;
  }
}

.active-font {
  color: #007a93;
}
.planned-font {
  color: $rich-black;
}
