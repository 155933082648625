.email-page {
    padding-right: 15px;
    .input-field {
        width: 100%;
        height: calc(2.25rem + 2px);
        padding: .375rem .75rem;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.5;
        background-clip: padding-box;
        border-radius: .25rem;
        outline: none;
        //margin-left: 8px;
    }
}