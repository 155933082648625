@import "./../../../styles/variables";
.more-action {
  background-color: transparent;
  top: 5px !important;
  left: -8px !important;
  @media screen\0 {
    left: 6px !important;
  }
  width: auto !important;
  .popover-body {
    display: flex;
    flex-direction: column;
    padding: 0;
    min-width: 175px;
    .action {
      font-size: 12px;
      height: 47px;
      border-radius: 0;
      text-align: left;
      &:first-child {
        border-radius: 2px 2px 0 0;
        border-bottom: solid 1px $teal-blue;
      }
      &:last-child {
        border-radius: 0 0 2px 2px;
      }
      &:only-child {
        border-radius: 2px;
      }
      &:hover {
        background-color: $teal-blue !important;
      }
      .end-event {
        width: 30px;
      }
    }
  }
  .btn-primary:disabled {
    background-color: $btn-primary !important;
    opacity: unset !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
}

.popup-more {
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup-more,
.popup-more button {
  // background-color: #707070 !important;
  background-color: rgba(0,0,0,0) !important;
}

.upcoming .popup-more,
.upcoming .popup-more button {
  // background-color: $rich-black !important;
  background-color: rgba(0,0,0,0) !important;
}

.popover-bttn:disabled {
  cursor: not-allowed;
  cursor: not-allowed;
}