@import "./../../../styles/_generic";

.sideNav-container {
  .sideNav {
    user-select: none;
    width: 0;
    transition: width 0.2s;
    overflow: hidden;
    white-space: nowrap;
    padding-top: 16px;
    &.closed {
      .text {
        visibility: hidden;
      }
    }

    .nav-item {
      padding: 16px 16px 16px 22px;
      text-decoration: none;
      color: $font-color;
      display: block;
      transition: 0.3s;
      height: 50px;
      &.grey-bg {
        background-color: $secondary-dev !important;
        cursor: not-allowed
      }
      &.active {
        background-color: $btn-primary;
      }

      &:hover {
        background-color: $btn-primary;
      }
      &.disabled-link {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.2;
      }
    }
    hr {
      border: 1px solid rgba(51, 51, 51, 1);
    }
    .api-validator {
      width: 22px;
      padding-right: 0px;
      margin-right: 6px;
    }
    .email {
      width: 22px;
      padding-right: 0px;
      margin-right: 6px;
    }
  }
}

.side-nav-margin {
  transition: margin-left 0.2s;
}

.hamburger {
  height: 60px;
  padding: 20px;
  background-color: $dark-jungle-green;
  &.open {
    padding: 10px 20px 0;
    &.list-container {
      background: red;
    }
  }

  #nav-icon1 {
    width: 20px;
    height: 100%;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: $blue-green;
      border-radius: 4px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
    }

    span:nth-child(1) {
      top: 0;
    }

    span:nth-child(2) {
      top: 6px;
    }

    span:nth-child(3) {
      top: 12px;
    }
    &.open span:nth-child(1) {
      top: 8px;
      transform: rotate(135deg);
    }

    &.open span:nth-child(2) {
      opacity: 0;
    }

    &.open span:nth-child(3) {
      top: 8px;
      transform: rotate(-135deg);
    }
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(190px);
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(190px);
  }

  100% {
    transform: translateX(0%);
  }
}

@media (min-width: 768px) {
  .side-nav-margin {
    margin-left: 260px !important;
  }
  .side-nav-close-width {
    width: 60px !important;
    transition: width 0.2s;
  }
  .side-nav-width {
    width: 260px !important;
    transition: width 0.5s;
  }

  .side-nav-close-margin {
    margin-left: 60px !important;
    transition: margin-left 0.2s;
  }
  .sideNav-container {
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: $primary;
    overflow-x: hidden;
    transition: width 0.2s;
  }
  .hamburger {
    #nav-icon1 {
      &.open {
        animation: slide-out 0.1s forwards;
      }
      &.close-nav {
        animation: slide-in 0.1s forwards;
      }
    }
  }
}
@media (max-width: 767px) {
  .header {
    padding-left: 70px;
    .logo-holder {
      justify-content: center;
    }
    .right {
      .language {
        .btn-group {
          .dropdown-toggle {
            border: none;
          }
        }
      }
    }
  }
  .sideNav-container {
    width: 100%;
    z-index: 10;
    position: absolute;
    left: 0;
  }
  .sideNav-container .sideNav {
    width: 100% !important;
    z-index: 10;
    background: black;
    position: fixed;
    top: 60px;
    right: 0;
    bottom: 0;
    height: 100%;
    transition: all ease 0.5s;
    &.closed {
      height: 0;
    }
  }
  .side-nav-close-margin {
    .sideNav-container {
      width: 60px;
    }
  }
  .hamburger {
    background: $black;
    #nav-icon1 {
      &.open {
        top: 20px;
        right: 30px;
        position: absolute;
      }
    }
  }
  .desktop-only {
    display: none !important;
  }
  .greetings {
    &.desktop-only {
      display: none !important;
    }
  }
}

@media screen and (min-width: 848px) {
  .toggle {
    display: none;
  }
}

.greetings {
  &.close-nav {
    display: none !important;
  }
}
