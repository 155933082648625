@import'./../../styles/variables';

.system-function-mapping {
  .react-bootstrap-table {
    table {
      border-collapse: separate;
      th, td {
        text-align: center;
      }

      td:first-child,
      th:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
      }

      td:nth-child(2),
      th:nth-child(2) {
        position: sticky;
        left: 83px;
        z-index: 1;
        border-collapse: separate !important;
        -webkit-box-shadow: 3px 0px 4px 0px $box-shadow;
        -moz-box-shadow:inset 3px 0px 4px 0px $box-shadow;
        box-shadow: 3px 0px 4px 0px $box-shadow;
      }

      td:last-child,
      th:last-child {
        position: relative;
        box-shadow: none;
      }
    }
  }
}